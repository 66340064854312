<template>
  <div v-bind="attrs">
    <zh-table :data="list" v-bind="attrs" @row-click="clickOrderDetail" height="350">
      <el-table-column
          width="100"
          label="序号"
          align="center"
      >
        <template #default="scope">
          <div>{{ scope.$index + 1 }}</div>
        </template>
      </el-table-column>
      <template v-for="item in titleList" :key="item.fieldKey">
        <el-table-column
            v-if="item.fieldKey === 'alarmTypeDesc'"
            :prop="item.fieldKey"
            :label="item.title"
            align="center"
        >
          <template #default="scope">
            <div class="alarm-status" :class="`status${scope.row.alarmType}`">
              {{ scope.row.alarmTypeDesc }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            v-else
            align="center"
            :prop="item.fieldKey"
            :label="item.title"
            :class-name="item.className || 'table_text'"
            show-overflow-tooltip
        >
          <template #default="{row}">
            {{ row[item.fieldKey] || '--' }}
          </template>
        </el-table-column>
      </template>
    </zh-table>
    <Inventroy v-model="inventroyShow" title="库存详情" :stock-id="chooseGoodsInfo.stockId" :goodsInfo='chooseGoodsInfo'
               @close="inventroyShow = false"></Inventroy>
    <SlowSales :goodsInfo='chooseGoodsInfo' :stock-id="chooseGoodsInfo.stockId"
               :value-string="chooseGoodsInfo.valueString" :alarmType='chooseGoodsInfo.alarmType' title="查看明细"
               v-model="slowSalesShow"></SlowSales>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref, toRefs, useAttrs} from "vue";
import {pendingConfig} from "@views/home/component/config";
import Inventroy from "@views/inStock/inventoryCheck/components/component/inventroy.vue";
import {stockAlarmDetail, stockAlarmLook} from "@utils/api/inStock/inventory";
import SlowSales from "@views/inStock/inventoryCheck/components/component/slowSales.vue";
import {orderBelong} from "@views/order/orderConfig";

const attrs = useAttrs()
const props = defineProps({
  list: {
    type: Array,
    default: () => []
  }
})
const {list} = toRefs(props)
const titleList = ref(pendingConfig.warning.table)
const {proxy} = getCurrentInstance();
const chooseGoodsInfo = ref({
  alarmType: ""
})
const slowSalesShow = ref(false)
const inventroyShow = ref(false)
const clickOrderDetail = async (row) => {
  chooseGoodsInfo.value = {}
  stockAlarmLook({
    productId: row.productId, modelId: row.modelId, alarmType: row.alarmType, stockId: row.stockId
  })
  let res = await stockAlarmDetail({productId: row.productId, modelId: row.modelId, alarmType: row.alarmType,stockId:row.stockId});
  if (res.code !== 0) {
    proxy.$message.error(res.msg);
    return;
  }
  if (row.alarmType === 1) {
    chooseGoodsInfo.value = Object.assign({}, row, res.data);
    inventroyShow.value = true
    return
  }
  if (row.alarmType === 2) {
    chooseGoodsInfo.value = Object.assign({
      valueString: '剩余过期时间（天）',
      valueTitle: '查看明细',
    }, row, res.data);
    slowSalesShow.value = true
    return
  }
  if (row.alarmType === 3) {
    chooseGoodsInfo.value = Object.assign({
      valueString: '在库时间（天）',
      valueTitle: '查看明细',
    }, row, res.data);
    slowSalesShow.value = true
  }
};

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "WarningList",
});
</script>

<style lang="scss" src="../index.scss" scoped>

</style>
