<template>
  <div class="pending-content">
    <board-content size="2">
      <div class="board_title dp_f">
        <div class="title">待处理事项</div>
        <tab-select :options="tabList" @change="changTable" v-if="isOver"></tab-select>
      </div>
      <div class="tabList">
        <!--        <order-list v-show="tabChecked === 0" class="animate__animated animate__bounceInRight"></order-list>-->
        <!--        <warning-list v-show="tabChecked === 1" class="animate__animated animate__bounceInLeft"></warning-list>-->
        <order-list v-show="tabChecked === 0" :list="orderIndexList" @change-list="getList"
                    class="animate__animated animate__fadeIn"></order-list>
        <warning-list v-show="tabChecked === 1" :list="warningIndexList"
                      class="animate__animated animate__fadeIn"></warning-list>
      </div>
    </board-content>
  </div>
</template>

<script setup>
import TabSelect from "@components/common/tabSelect/index.vue";
import BoardContent from "@components/common/boardContent/index.vue";
import {getCurrentInstance, onMounted, ref, toRefs} from "vue";
import OrderList from "@views/home/component/pending/component/orderList.vue";
import WarningList from "@views/home/component/pending/component/warningList.vue";
import {pendingConfig} from '../config'
import {pendingList} from "@/utils/api/custom";

const props = defineProps({
  stockId: {
    type: [String, Number],
    default: ""
  }
})
const {stockId} = toRefs(props)
const orderIndexList = ref([])
const warningIndexList = ref([])
const tabList = ref([
  {
    label: `业务订单`,
    value: 1,
    tableKey: 'businessOrder'
  },
  {
    label: `预警事项`,
    value: 2,
    tableKey: 'warning'
  },
])
const titleList = ref(pendingConfig.businessOrder.table)
const tabChecked = ref(0)
const {proxy} = getCurrentInstance();
const changTable = (e) => {
  tabChecked.value = tabList.value.findIndex(item => item.value === e.value)
}
const isOver = ref(false)
const getList = () => {
  let params = {
    stockId: stockId.value,
  }
  pendingList(params).then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    tabList.value = [
      {
        label: `业务订单(${res.data.orderCount})`,
        value: 1,
        tableKey: 'businessOrder'
      },
      {
        label: `预警事项(${res.data.alarmCount})`,
        value: 2,
        tableKey: 'warning'
      },
    ]
    isOver.value = true
    let list1 = res.data?.orderList || []
    let list2 = res.data?.alarmList || []
    list1.forEach(item => {
      item.createTime = proxy.$filters.timeFormat(item.createTime, 1)
    })
    list2.forEach(item => {
      if (item.alarmType === 1) {
        item.alarmCount = '--'
      }
      if(item.scattered === false){
       item.scattered = 0
      }else if(item.scattered === true){
        item.scattered = 1
      }
    })
    orderIndexList.value = list1
    warningIndexList.value = res.data?.alarmList || []

  })
}

onMounted(() => {
  getList()
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "PendingOrder",
});
</script>

<style lang="scss" src="./index.scss" scoped></style>
