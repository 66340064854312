<template>
<div v-if="navList.length > 0">
  <draggable
      class="home_nav dp_f "
    :list="navList"
    ghost-class="ghost"
    chosen-class="chosenClass"
    animation="300"
    @start="onStart"
    @end="onEnd"
    item-key="menuId"
  >
    <template #item="{ element }">
      <div class="navItem cur_p animate__animated animate__fadeIn">
        <div class="item" :class="`menu-${element.fatherId}`">
          <div class="icon"><i :class="element.icon" class="iconfont "/></div>
        <div class="name">{{ element.title }}</div>
        <div class="deleteNav">
          <el-icon @click="deleteNav(element)"><Close /></el-icon>
        </div>
        </div>
      </div>
    </template>
  </draggable>
</div>
</template>

<script>
import {getCurrentInstance, onMounted, ref, toRefs, watch} from "vue";
import {Close} from "@element-plus/icons-vue";
import draggable from "vuedraggable";
import {getMenuDislike} from '../../../utils/api/system.js'
import {cloneDeep} from "lodash";

export default {
  name: "homeNav",
  components: {
    Close,
    draggable,
  },
  props: {
    likeList: Array
  },
  methods: {},
  setup(props, { emit }) {
    const {likeList} = toRefs(props)
    const {proxy} = getCurrentInstance()
    const navList = ref([]);
    const getNav = () => {
      navList.value = cloneDeep(likeList.value)
    }
    watch(() => likeList.value, (val) => {
      getNav()
    })
    function onStart() {
    }
    function onEnd() {
     emit('changList',navList.value)
    }
    async function deleteNav(e) {
      let index = navList.value.findIndex((item) => e.menuId === item.menuId)
      let data = await getMenuDislike({
        menuId: e.menuId
      })
      if (data.code !== 0) {
      proxy.$message.error(data.msg)
      return
    }
      proxy.$message.success('移除成功')
      navList.value.splice(index, 1);
      emit('changList', navList.value)
    }

    onMounted(() => {
      getNav()
    })
    return {
      navList,
      onStart,
      onEnd,
      deleteNav
    };
  },
};
</script>
<script setup></script>

<style lang="scss" scoped>
.home_nav {
  flex-wrap: wrap;
  justify-content: flex-start !important;
  padding: 20px;
  background: #fff;
  .navItem {
    background: #fff;
    .item{
      background: #737eff;
      border-radius: 16px;
      //animation-name: shake;
      // animation-duration: 1s; /* 抖动持续时间 */
      // animation-iteration-count: infinite; /* 持续抖动 */
      &.menu-1 {
        background: #7C71F5;
      }

      &.menu-2 {
        background: #3859FF;
      }

      &.menu-3 {
        background: #57B0FF;
      }

      &.menu-4 {
        background: #F7B1A6;
      }

      &.menu-5 {
        background: #48CB9F;
      }

      &.menu-6 {
        background: #989BD2;
      }

      &.menu-36 {
        background: #8BC1D9;
      }
    }
    width: 15%;
    margin-right: 2%;
    height: 72px;
    color: #fff;
    line-height: 72px;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;

    &:nth-child(6n) {
      margin-right: 0;
    }
    .deleteNav {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
      position: absolute;
      right: -2px;
      top: -2px;
      color: #fff;
      background-color: $fsColor2;
    }
    .name {
      display: inline;
    }
    .icon {
      width: 40px;
      height: 40px;
      line-height: 40px;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.2);
      text-align: center;

      .iconfont {
        line-height: 40px;
      }
    }
  }
}
</style>
