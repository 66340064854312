<template>
  <div>
    <div class="zh_home_header dp_f">
      <div class="left dp_f">
        <div class="logo">
          <Logo class="logoPic"/>
        </div>
        <div class="menu">
          <div class="item dp_f cur_p" v-for="(item,index) in menuList" :key="index" @click="goLink(item)">
            <i :class="item.icon" class="icon iconfont" />
            <div class="name">{{item.title}}</div>
          </div>
        </div>
      </div>
      <user-info-top where="layout">
        <el-button class="loginBtn" @click='upDateHome' :class="isUpdate?'active':''"><img v-if="isUpdate"
                                                                                           src="../../../assets/img/home/update2.png"
                                                                                           class="up_icon"/> <img v-else
                                                                                                                  src="../../../assets/img/home/update1.png"
                                                                                                                  class="up_icon"
                                                                                                                  alt=""/>
          {{ isUpdate ? '编辑完成' : '首页管理' }}
        </el-button>
      </user-info-top>
    </div>
  </div>
</template>


<script>
import {getCurrentInstance, onMounted, reactive, ref, toRefs} from "vue";
import {useRouter} from "vue-router";
import Logo from '@components/logo.vue'
import UserInfoTop from "@components/common/userInfo/index.vue";

export default {
  components: {
    Logo,
    UserInfoTop
  },
  name: "Head",
  props: {
    type: String,
    menuList: Array
  },
  setup(props,{emit}) {
    const router = useRouter();
    const {proxy} = getCurrentInstance()
    const {menuList} = toRefs(props)
    const isUpdate = ref(false)
    const upDateHome = function(){
      isUpdate.value = !isUpdate.value
      emit('change',{})
    }
    const goLink = (item)=>{
      if(!item.jumpUrl) return
      router.push({
        path:item.jumpUrl
      })
    }
    const userInfo = reactive({
      companyName:"",
      userName:""
    })
    //获取用户信息
    const getUserInfo = ()=>{
      let user = proxy.$cookies.get('userInfo')
      if (user) {
        userInfo.companyName = user.companyName
        userInfo.userName = user.userName
      }
    }

    onMounted(()=>{
      getUserInfo()
    })
    return {
      menuList,
      upDateHome,
      goLink,
      userInfo,
      isUpdate
    };
  },
};
</script>
<script setup>

</script>

<style lang="scss" scoped>
.zh_home_header{
  background-color: #fff;
  padding: 0 20px 0 64px;
  height: 56px;
  box-sizing: border-box;
  .left{
    .logo{
      display: flex;
      align-items: center;

      .logoPic {
        position: relative;
        top: -6px;
      }
      .company_name{
        font-size: 18px;
        // font-weight: bold;
        color: #161829;
        margin-left: 6px;
      }
    }
    .menu{
      display: flex;
      align-items: center;
      margin-left: calc(100vw * 0.03);
      .item {
      padding: 0 calc(100vw * 0.01);
      margin-right: 1vw;
      line-height: 50px;
      width: max-content;
      font-size: $fs14;
      height: 50px;
      border-radius: $borderRadius8;
      transition: 0.5s all ease-in-out;
      &:hover {
        background: $bgColor;
        color: #fff;
      }
      .icon {
        margin-right: 8px;
        position: relative;
        top: 1px;
      }
    }
    }
  }
  .userInfo{
    .loginBtn{
      font-size: 12px;
      width: 120px;
      text-align: center;
      &.active{
        color: $fsColor3;
        border: 1px solid $fsColor3;
      }
      .up_icon{
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
  }
}

</style>
