<template>
  <div class="common-layout">
    <el-container>
      <el-header height="56px">
        <HeadTop @change="change" :menuList="menu"></HeadTop>
      </el-header>
      <el-main class="mainContent">
        <Nav v-show="!isUpdate" :like-list="likeList"/>
        <UpDateNav v-show="isUpdate" class="" @changList="updateChange" :like-list="likeList"/>
        <board-header quick-search-year quick-search-yesterday v-model:formData="formData"></board-header>
        <pending :stockId="formData.stockId"></pending>
        <Board :sort="isUpdate" :formData="formData" @changList="changeBoard"></Board>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {reactive, ref, toRefs, unref} from 'vue'
import HeadTop from './component/head.vue'
import Nav from './component/nav.vue'
import UpDateNav from './component/upDateNav.vue'
import {menuSort} from '@utils/api/system.js'
import {customSort} from '@/utils/api/custom.js';
import Board from './component/board.vue'
import BoardHeader from "@components/common/boardHeader/index.vue";
import Pending from "@views/home/component/pending/index.vue";
import {getNavLikeList} from '@/utils/api/system.js';
import useMenu from "@utils/hooks/useMenu";

export default {
  components: {
    HeadTop,
    Nav,
    UpDateNav,
    Board,
    BoardHeader,
    Pending
  },
  setup () {
    const data = reactive({
        isUpdate:false,
        currentPage:1,
        dialogVisible:true,
        radio:1
      })
    const formData = ref({
      time: [],
      stockId: '',
      timeType: 1,
    })
    const {menu} = useMenu((list) => {
      getLikeList(list)
    })
    const likeList = ref([])
    const getLikeList = async (dataList) => {
      let data = await getNavLikeList()
      if (!data.data.list) return
      let list2 = data.data.list
      list2.forEach(item => {
        let list = unref(dataList)
        if (!list.length) return
        for (let i = 0; i < list.length; i++) {
          if (!list[i].childList.length) return
          for (let j = 0; j < list[i].childList.length; j++) {
            if (item.menuId === list[i].childList[j].menuId) {
              item.fatherId = list[i].menuId
              return;
            }
          }
        }
      })
      likeList.value = list2
    }
    async function change() {
       //发送列表
       if(data.isUpdate){
         await Promise.allSettled([
           menuSort(upDateList.value),
           customSort(boardList.value)
         ])
       }
       data.isUpdate = !data.isUpdate
      }
      const upDateList = ref([])
      const updateChange =(e)=>{
        if(e?.length > 0){
        e.forEach((item,index)=>{
          item.sort = index + 1
        })
       }
        likeList.value = e
        upDateList.value = e
      }
    const boardList = ref([])
    const changeBoard = (e) => {
      if (e?.length > 0) {
        e = e.map((item, index) => {
          return {
            unitId: item.unitId,
            sort: index + 1
          }
        })
      }
      boardList.value = e
    }
      return {
        ...toRefs(data),
        updateChange,
        change,
        changeBoard,
        formData,
        menu,
        likeList
      }
  },
}
</script>

<style lang='scss' scoped>
.mainContent{
  padding-bottom: 32px;
  min-height: calc(100vh - 56px);
  border-top: 1px solid $borderColor;
}
  .common-layout{
    background: #F2F4F7;
    min-height: 100vh;

    :deep(.el-header) {
      padding: 0;
    }

    :deep(.el-main) {
      padding: 0;
    }
  }
  .textBox{
    padding: 0 32px;
    .text{
      margin-top: 20px;
    }
    .val{
      margin: 30px 0;
    }
  }
</style>
