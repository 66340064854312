<template>
  <div class="zh-select" v-bind="attrs">
    <div class="tab"
         :style="{left: tabConfig.tabPosition,height:tabConfig.offsetHeight,width:tabConfig.offsetWidth }"></div>
    <div class="zh-select-options" ref="selectRefs">
      <div
          v-for="(option, index) in options"
          :key="index"
          class="zh-select-option"
          :class="{ active: tabChecked === index }"
          @click="clickItem($event, option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted, reactive, ref, toRefs, useAttrs } from "vue";
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  activeValue: {
    type: Number,
    default: 0,
  },
})
const emit = defineEmits(["update:activeValue", 'change']);
const attrs = useAttrs()
const tabChecked = ref(0)
const {options, activeValue} = toRefs(props)
const selectRefs = ref(null)
const tabConfig = reactive({
  tabPosition: '',
  offsetHeight: '',
  offsetWidth: '',
})

//设置tabChecked
const setTabChecked = () => {
  if (activeValue.value) {
    tabChecked.value = options.value.findIndex((item) => item.value === activeValue.value)
  } else {
    tabChecked.value = 0
  }
  setTabPosition(document.getElementsByClassName('zh-select-option')[tabChecked.value])
}

const setTabPosition = (event) => {
  if (!event) return
  if (event.target) {
    tabConfig.tabPosition = `${event.target.offsetLeft + 2}px`
    tabConfig.offsetHeight = `${event.target.offsetHeight}px`
    tabConfig.offsetWidth = `${event.target.offsetWidth}px`
  } else {
    tabConfig.tabPosition = `${event.offsetLeft + 2}px`
    tabConfig.offsetHeight = `${event.offsetHeight}px`
    tabConfig.offsetWidth = `${event.offsetWidth}px`
  }
}

const clickItem = (event, option) => {
  let index = options.value.findIndex((item) => item.value === option.value)
  if (index === tabChecked.value) return
  emit('update:activeValue', option.value)
  emit('change', option)
  tabChecked.value = index
  setTabPosition(event)
}

//初始化页面
function initPage() {
  setTabChecked()
}

onMounted(() => {
  initPage()
})
</script>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "TabSelect",
})
</script>
<style lang="scss" src="./index.scss" scoped></style>