export const componentConfig = [
  {
    unitId:1,
    component:'ProcurementTotal',
  },
  {
    unitId:2,
    component:'ProcurementSupplierTop5'
  },
  {
    unitId:3,
    component:'ProcurementGoodsTop5'
  },
  {
    unitId:4,
    component:'ProcurementBoard'
  },
  {
    unitId:5,
    component:'ProcurementOrder'
  },
  {
    unitId:6,
    component:'InboundTotal'
  },
  {
    unitId:7,
    component:'InboundSupplierTop5'
  },
  {
    unitId:8,
    component:'InboundGoodsTop5'
  },
  {
    unitId:9,
    component:'InboundBoard'
  },
  {
    unitId:10,
    component:'InboundOrder'
  },
  {
    unitId:11,
    component:'InboundRuku'
  },
]

export const pendingConfig = {
  businessOrder: {
    table: [
      {
        title: '订单编号',
          fieldKey: 'no',
        className: 'table_text2',
      },
      {
        title: '订单类型',
        fieldKey: 'orderType',
      },
      {
        title: '产品数量',
          fieldKey: 'productCount',
        className: 'table_text2',
      },
      {
          title: '仓库名称',
        fieldKey: 'stockName',
        className: 'table_text2',
      },
      {
        title: '创建人',
          fieldKey: 'createUserName',
      },
      {
        title: '创建时间',
        fieldKey: 'createTime',
      },
    ]
  },
  warning: {
    table: [
      {
        title: '产品名称',
        fieldKey: 'productName',
          className: 'table_text2',
      },
      {
        title: '简称',
        fieldKey: 'subName',
          className: 'table_text2',
      },
      {
        title: '产品单位',
        fieldKey: 'unitName',
      },
      {
        title: '规格/型号',
        fieldKey: 'modelName',
          className: 'table_text2',
      },
      {
        title: '预警类型',
          fieldKey: 'alarmTypeDesc',
      },
      {
        title: '预警数量',
          fieldKey: 'alarmCount',
          className: 'table_text2',
      },
      {
        title: '所属仓库',
        fieldKey: 'stockName',
          className: 'table_text2',
      },
    ]
  }
}

export const orderRouter = {
    1: {
        router: '/procurement/order/1'
    },
    3: {
        router: '/inbound/salesRefund/3'
    },
    7: {
        router: '/outStock/sellOut/7'
    },
    10: {
        router: '/outStock/miningRetreatOutStock/10'
    }
}