<template>
  <draggable
      class="boardContent"
      :list="tableData"
      animation="300"
      @end="onEnd"
      :sort="sort"
      item-key="timer"
  >
    <template #item="{ element }">
      <component :is='element.component' v-model:formData="formData" :class="[element.className,'animate__animated']"
                 v-model:isLike="element.isLike" @like='likeUnit(element)'></component>
    </template>
  </draggable>
</template>

<script setup>
import {useStore} from 'vuex';
import {getCurrentInstance, ref, shallowRef, toRefs, watch} from "vue";
import ProcurementTotal from '../../procurement/components/total.vue'
import ProcurementSupplierTop5 from '../../procurement/components/supplierTop5.vue'
import ProcurementGoodsTop5 from '../../procurement/components/goodsTop5.vue'
import ProcurementBoard from '../../procurement/components/board.vue';
import ProcurementOrder from '../../procurement/components/order.vue';
import InboundTotal from '../../inbound/components/total.vue';
import InboundSupplierTop5 from '../../inbound/components/supplierTop5.vue';
import InboundGoodsTop5 from '../../inbound/components/goodsTop5.vue';
import InboundBoard from '../../inbound/components/board.vue';
import InboundOrder from '../../inbound/components/order.vue';
import InboundRuku from '../../inbound/components/ruku.vue';
import OutStockTotal from '@views/outStock/board/components/inStockWaning.vue';
import OutStockGoodsTop5 from '@views/outStock/board/components/goodsTop5.vue';
import OutStockPunterTop5 from '@views/outStock/board/components/supplierTop5.vue';
import OutStockBoard from '@views/outStock/board/components/board.vue';
import OutStockSell from '@views/outStock/board/components/sellOut.vue';
import OutStockTrend from '@views/outStock/board/components/trend.vue';
import InStockBoard from '@views/inStock/components/board.vue';
import InStockGoodsTop5 from '@views/inStock/components/goodsTop5.vue';
import InStockTotal from '@views/inStock/components/total.vue';
import InStockWaning from '@views/inStock/components/inStockWaning.vue';
import TimeWarning from '@views/inStock/components/timeWarning.vue';
import Unsalable from '@views/inStock/components/unsalable.vue';

import moment from 'moment'
import {customAdd, customDel, customList} from '@/utils/api/custom.js';
import draggable from "vuedraggable";

const props = defineProps({
  sort: {
    type: Boolean,
    default: false
  },
  formData: {
    type: Object,
    default: () => {
    }
  },
})
const {sort, formData} = toRefs(props)

const { proxy } = getCurrentInstance();
const store = useStore();
const tableData = ref([])


const showLoading = () => {
  tableData.value.forEach(item => {
    item.timer = new Date().getTime() + item.unitId || 0
  })
}
let star = moment(new Date()).subtract(1, 'days')._d
star = moment(star).format('yyyy-MM-DD') + ' 00:00:00'
let end = moment(new Date()).subtract(1, 'days')._d
end = moment(end).format('yyyy-MM-DD') + ' 23:59:59'

watch(
    () => formData.value,
    () => {
      showLoading()
    }, {
      deep: true
    }
);
const onEnd = () => {
  proxy.$emit('changList', tableData.value)
}
const componentConfig =[
  {
    unitId:1,
    component: shallowRef(ProcurementTotal)
  },
  {
    unitId:2,
    component: shallowRef(ProcurementSupplierTop5)
  },
  {
    unitId:3,
    component: shallowRef(ProcurementGoodsTop5)
  },
  {
    unitId:4,
    component: shallowRef(ProcurementBoard)
  },
  {
    unitId:5,
    component: shallowRef(ProcurementOrder)
  },
  {
    unitId:6,
    component: shallowRef(InboundTotal)
  },
  {
    unitId:7,
    component: shallowRef(InboundSupplierTop5)
  },
  {
    unitId:8,
    component: shallowRef(InboundGoodsTop5)
  },
  {
    unitId:9,
    component: shallowRef(InboundBoard)
  },
  {
    unitId:10,
    component: shallowRef(InboundOrder)
  },
  {
    unitId:11,
    component: shallowRef(InboundRuku)
  },
  {
    unitId: 103,
    component: shallowRef(OutStockTotal),
  },

  {
    unitId: 101,
    component: shallowRef(OutStockBoard),
  },
  {
    unitId: 102,
    component: shallowRef(OutStockGoodsTop5),
  },

  {
    unitId: 104,
    component: shallowRef(OutStockPunterTop5),
  },
  {
    unitId: 105,
    component: shallowRef(OutStockSell),
  },
  {
    unitId: 100,
    component: shallowRef(OutStockTrend),
  },
  {
    unitId: 14,
    isLike: false,
    component: shallowRef(InStockBoard),
    timer: 0
  },
  {
    unitId: 15,
    isLike: false,
    component: shallowRef(InStockTotal),
    timer: 0
  },
  {
    unitId: 16,
    isLike: false,
    component: shallowRef(TimeWarning),
    timer: 0
  },
  {
    unitId: 17,
    isLike: false,
    component: shallowRef(Unsalable),
    timer: 0
  },
  {
    unitId: 12,
    isLike: false,
    component: shallowRef(InStockWaning),
    timer: 0
  },
  {
    unitId: 13,
    isLike: false,
    component: shallowRef(InStockGoodsTop5),
    timer: 0
  },
]

const getList = ()=>{
  customList().then(res => {
    if (res.code === 0) {
      if (res?.data?.list?.length > 0) {
        let list = res?.data?.list || []
        let arr = []
        list.forEach(item => {
          let obj = componentConfig.find(i => i.unitId === item.unitId)
          if (obj) {
            obj.isLike = true
            obj.className = ''
            obj.timer = 0
            arr.push(obj)
          }
        })
        tableData.value = arr
      }
    }
  })
}
getList()
const likeUnit = (e) => {
  if (!e.type) {
    customDel({unitId: e.unitId}).then(res => {
      if (res.code === 0) {
        let index = tableData.value.findIndex(item => item.unitId === e.unitId)
        tableData.value[index].className = 'animate__zoomOut'
        setTimeout(() => {
          tableData.value.splice(index, 1)
        }, 500)
        proxy.$message.success('取消成功')
      } else {
        proxy.$message.error(res.msg)
      }
    })
  } else {
    customAdd({unitId: e.unitId}).then(res => {
      if (res.code === 0) {
        tableData.value.forEach(item => {
          if (item.unitId === e.unitId) {
            item.isLike = true
          }
        })
        proxy.$message.success('收藏成功')
      } else {
        proxy.$message.error(res.msg)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.boardContent {
  padding-top: 0 !important;
}
</style>