<template>
  <div v-bind="attrs">
    <zh-table :data="list" @row-click="checkGoods" height="350">
      <el-table-column
          width="100"
          label="序号"
          align="center"
      >
        <template #default="scope">
          <div>{{ scope.$index + 1 }}</div>
        </template>
      </el-table-column>
      <template v-for="item in titleList" :key="item.fieldKey">
        <el-table-column
            v-if="item.fieldKey === 'orderType'"
            :prop="item.fieldKey"
            :label="item.title"
            align="center"
        >
          <template #default="scope">
            <div class="label-status" :class="`status${scope.row.orderType}`">
              {{ orderBelong[scope.row.orderType + ''] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            v-else-if="item.fieldKey === 'productCount'"
            :prop="item.fieldKey"
            :label="item.title"
            align="center"
        >
          <template #default="scope">
            {{ scope.row.productCount || '--' }}
          </template>
        </el-table-column>
        <el-table-column
            v-else
            align="center"
            :prop="item.fieldKey"
            :label="item.title"
            :class-name="item.className || 'table_text'"
            show-overflow-tooltip
        >
        </el-table-column>
      </template>
    </zh-table>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref, toRefs, useAttrs} from "vue";
import ZhTable from "@components/element/table.vue";
import {pendingConfig} from "@views/home/component/config";
import {orderBelong} from "@views/order/orderConfig";
import {useStore} from "vuex";
import {orderListDeatil} from "@utils/api/order";
import {reactiveAssign} from "@utils/tool";
import {cloneDeep} from "lodash";
import {useRouter} from "vue-router";
import {orderRouter} from '../../config'

const store = useStore()
const props = defineProps({
  list: {
    type: Array,
    default: () => []
  }
})
const router = useRouter()
const {list} = toRefs(props)
const attrs = useAttrs()
const titleList = ref(pendingConfig.businessOrder.table)
const {proxy} = getCurrentInstance();
const orderShow = ref(false);
const allocateShow = ref(false);
const emit = defineEmits(["changeList"]);
const checkGoods = (e) => {
  store.commit("PURCHASE_ORDER_INFO", {});
  let obj = cloneDeep(e);
  if (e.orderType === 8 || e.orderType === 4) {
    obj.orderType === 4 ? obj.orderType = 2 : obj.orderType = 1
    obj.transferNo = obj.no
    store.commit("PURCHASE_ORDER_INFO", obj);
    let str = obj.orderType === 2 ? `/inbound/allocateV2/${obj.orderType}` : `/outStock/allocateV2/${obj.orderType}`
    router.push({
      path: str,
    })
    return
  }
  if (e.orderType === 5 || e.orderType === 9) {
    obj.inventoryNo = obj.no
    store.commit("PURCHASE_ORDER_INFO", obj);
    router.push({
      path: `inStock/inventoryRecord`,
    })
    return
  }
  obj.orderSn = obj.no
  getGoodsListDet(obj)
}

const getGoodsListDet = (e) => {
  orderListDeatil({
    orderSn: e.orderSn,
    orderType: e.orderType,
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    if (res.data?.time) {
      res.data.time = proxy.$filters.timeFormat(res.data.time, 1)
    }
    if (res.data?.stockTime) {
      res.data.stockTime = proxy.$filters.timeFormat(res.data.stockTime, 1)
    }
    reactiveAssign(e, res.data);
    store.commit("PURCHASE_ORDER_INFO", e);
    router.push({
      path: orderRouter[e.orderType]?.router,
    })
  })
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "OrderList",
});
</script>

<style lang="scss" src="../index.scss" scoped>

</style>
