

<template>
  <div class="home_nav" v-if="navList.length > 0">
     <el-carousel trigger="click" height="100px" :autoplay="false" arrow='never' indicator-position="outside">
      <el-carousel-item v-for="(item,index) in navList" :key="index" >
        <div class="navItem dp_f animate__animated animate__fadeIn">
          <div class="item cur_p" v-for="(i,j) in item" :key='j' @click="goLink(i)" :class="`menu-${i.fatherId}`">
            <div class="icon"> <i :class="i.icon"  class="iconfont " /></div>
            <div class="name">{{i.title}}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>


<script>
import {ref, toRefs, unref, watch} from "vue";
import {useRouter} from 'vue-router';

export default {
  name:"homeNav",
  props: {
    likeList: Array
  },
  components: {
  },
  setup(props,{emit}) {
    const router = useRouter()
    const navList = ref([])
    const {likeList} = toRefs(props)
    watch(() => likeList.value, (val) => {
      getNav()
    })
    //获取金刚区列表
    const getNav = async ()=>{
      let list2 = unref(likeList)
      let navList2 = [];
      let navListLength = list2.length;
      let navListPage = Math.ceil(navListLength/6);
      for (let i = 0; i < navListPage; i++) {
        navList2[i] = list2.slice(i * 6, (i + 1) * 6);
      }
      navList.value = navList2
    }
    const goLink = (item)=>{
      if(!item.jumpUrl) return
      router.push({
        path:item.jumpUrl
      })
    }
   return {
      navList,
      goLink
    };
  },
};
</script>
<script setup>

</script>

<style lang="scss" scoped>
.home_nav{
  position: relative;
  background: #fff;
  height: 132px;
  .navItem{
    padding: 20px;
    justify-content: flex-start !important;
    .item{
      width: 15%;
      margin-right: 2%;
      height: 72px;
      border-radius: 16px;
      color: #fff;
      line-height: 72px;
      text-align: center;
      font-size: 14px;
      box-sizing: border-box;
      position: relative;
      transition:all 0.1s linear ;

      &:last-child {
        margin-right: 0;
      }

      &.menu-1 {
        background: #7C71F5;
      }

      &.menu-2 {
        background: #3859FF;
      }

      &.menu-3 {
        background: #57B0FF;
      }

      &.menu-4 {
        background: #F7B1A6;
      }

      &.menu-5 {
        background: #48CB9F;
      }

      &.menu-6 {
        background: #989BD2;
      }

      &.menu-36 {
        background: #8BC1D9;
      }
      .name{
        display: inline;
      }
      .icon{
        width: 40px;
        height: 40px;
        line-height: 40px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.2);
        text-align: center;
        .iconfont{
          line-height: 40px;
        }
      }
    }
  }
}
</style>
